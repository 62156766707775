import axios from 'axios'
import { useRouter } from 'next/router'
import { useEffect, useState, useContext } from 'react'
import Button from './Button'
import DialogPopup from './DialogPopup'
import Body from './typography/Body'
import Bravo from './typography/Bravo'
import useToken from '../hooks/useToken'
import { useCookies } from 'react-cookie'
import QuizContext from '~/context/QuizContext'
import Logo from './Logo'
import { useQuery } from 'react-query'
import VideoStreamingContext from '~/context/VideoStreamingContext'
import BackgroundMusicContext from '~/context/BackgroundMusicContext'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CheckCircle } from '~/components/icons/CheckCircle'
import { Transition } from '@headlessui/react'
import LogoLive from './LogoLive'
import ReactPlayer from 'react-player'
import Cast from './icons/Cast'
import DialogPopupWithBack from './DialogPopupWithBack'

export default function QuizNav({
  quiz_uuid,
  question = false,
  is_host = false,
  is_live = false,
  quizStatus = false,
  pin = false,
  logo,
  ad_takeover_url = false,
  sendToStartle = false,
  stopCastingToStartle = false,
  startleId = false
}) {
  const { quizData, setQuizData } = useContext(QuizContext)
  const { userCorrect } = quizData

  const [copied, setCopied] = useState(false)
  const [showAd, setShowAd] = useState(false)
  const { isStreaming, setIsStreaming, isMuted, setIsMuted, isFeedPublished } =
    useContext(VideoStreamingContext)
  const { isMusicMuted, setIsMusicMuted, isCasting, setIsCasting } = useContext(
    BackgroundMusicContext
  )
  const [isStreamingBlock, setIsStreamingBlock] = useState(false)
  const [endCastingOverlay, setEndCastingOverlay] = useState(false)
  const [castHighlight, setCastHighlight] = useState(true)
  const router = useRouter()

  const token = useToken()

  const [showDialog, setShowDialog] = useState(false)

  const [cookies, setCookie, deleteCookie] = useCookies([
    'quiz_uuid',
    'public_user_uuid',
    'user_uuid'
  ])

  useEffect(() => {
    setTimeout(() => {
      setCastHighlight(false)
    }, 8000)
  }, [])
  useEffect(() => {
    if (isCasting === true || isCasting === 'loading') {
      setCastHighlight(false)
    }
  }, [isCasting])

  useEffect(() => {
    setIsStreamingBlock(true)
  }, [isStreaming])

  useEffect(() => {
    setIsStreamingBlock(false)
  }, [isFeedPublished])

  const leaveGame = () => {
    if (is_live) {
      if (token) {
        router.push('/dashboard')
      } else {
        router.push('/join')
      }
    }
    if (is_host) {
      if (startleId) {
        stopCastingToStartle()
        setIsCasting(false)
      }
      axios
        .post(`${process.env.base_api}/quiz/${quiz_uuid}/end`, null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(function () {
          console.log('ended')
          setIsStreaming(false)
          router.push('/dashboard')
        })
        .catch(function (err) {
          console.log('err:%o', err)
          console.log('error')
        })
    } else {
      setQuizData({
        ...quizData,
        userCorrect: null
      })
      deleteCookie('quiz_uuid', { path: '/' })
      deleteCookie('public_user_uuid', { path: '/' })
      deleteCookie('user_name', { path: '/' })
      deleteCookie('user_uuid', { path: '/' })
      if (token) {
        router.push('/dashboard')
      } else {
        router.push('/join')
      }
    }
  }

  const playAd = () => {
    if (is_host && ad_takeover_url) {
      axios
        .post(`${process.env.base_api}/quiz/${quiz_uuid}/play-ad`, null, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(function () {
          console.log('ad played')
          setShowAd(true)
        })
        .catch(function (err) {
          console.log('err:%o', err)
          console.log('error')
        })
    }
  }

  const castHandler = (fromOverlay) => {
    if (!isCasting) {
      setIsCasting('loading')
      sendToStartle()
      setTimeout(() => {
        setIsCasting(true)
      }, 120000)
      return
    }
    if (isCasting && !fromOverlay) {
      setEndCastingOverlay(true)
    } else {
      if (fromOverlay) {
        setEndCastingOverlay(false)
        stopCastingToStartle()
        setIsCasting('loading')
        setTimeout(() => {
          setIsCasting(!isCasting)
        }, 30000)
      } else {
        setIsCasting(!isCasting)
        sendToStartle()
      }
    }
  }

  const { data: numberOfPlayers } = useQuery(
    'allPlayersAnswered',
    async () => {
      const res = await axios.get(
        `${process.env.base_engine}/player-count.php?quiz_uuid=${quiz_uuid}`
      )
      return res.data.count ?? false
    },
    {
      refetchInterval: 20000
    }
  )

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 1500)
    }
  }, [copied])

  useEffect(() => {
    axios
      .get(`${process.env.base_engine}/player-count.php?quiz_uuid=${quiz_uuid}`)
      .then(function (response) {
        // setNumberOfPlayers(response.data.count)
      })
      .catch(function (err) {
        console.log('err:%o', err)
      })
    if (is_live) {
      setIsMuted(false)
    }
  }, [quiz_uuid])

  const QRurl =
    typeof window !== 'undefined'
      ? `${window.location.origin}/join/?pin=${pin}`
      : `/join/?pin=${pin}`

  return (
    <>
      <DialogPopup
        openModal={() => setShowDialog(true)}
        closeModal={() => setShowDialog(false)}
        isOpen={showDialog}
      >
        <Bravo className="pb-20">
          Are you sure you want to leave this game?
        </Bravo>
        <Button
          className="mb-16"
          onClick={() => leaveGame()}
          label="Leave game"
        />
        <button onClick={() => setShowDialog(false)}>No, go back</button>
      </DialogPopup>
      <div className="bg-white rounded-bl-md rounded-br-md self-start top-0 w-full relative print:hidden">
        <div className="container">
          <nav className="flex items-center my-10 text-neutral-800 justify-between">
            <div className="flex items-center">
              {is_live ? (
                <LogoLive className="hidden md:block mr-20 w-[150px]" />
              ) : (
                <Logo className="hidden md:block mr-20 w-[150px]" />
              )}
              {numberOfPlayers !== false && numberOfPlayers > 0 && (
                <div className="bg-neutral-100 rounded-md flex items-center p-10">
                  <svg width="12" height="15" viewBox="0 0 12 15" fill="none">
                    <path
                      d="M6 2.16732C4.52724 2.16732 3.33333 3.36122 3.33333 4.83398C3.33333 6.30672 4.52724 7.50065 6 7.50065C7.47273 7.50065 8.66667 6.30672 8.66667 4.83398C8.66667 3.36122 7.47273 2.16732 6 2.16732ZM2 4.83398C2 2.62484 3.79086 0.833984 6 0.833984C8.20913 0.833984 10 2.62484 10 4.83398C10 7.04312 8.20913 8.83398 6 8.83398C3.79086 8.83398 2 7.04312 2 4.83398ZM3.33333 11.5007C2.22877 11.5007 1.33333 12.3961 1.33333 13.5007C1.33333 13.8689 1.03485 14.1673 0.666667 14.1673C0.29848 14.1673 0 13.8689 0 13.5007C0 11.6597 1.49239 10.1673 3.33333 10.1673H8.66667C10.5076 10.1673 12 11.6597 12 13.5007C12 13.8689 11.7015 14.1673 11.3333 14.1673C10.9651 14.1673 10.6667 13.8689 10.6667 13.5007C10.6667 12.3961 9.77127 11.5007 8.66667 11.5007H3.33333Z"
                      fill="#3D435E"
                    />
                  </svg>
                  <Body className="ml-5 lg:ml-10 flex items-center" small>
                    {numberOfPlayers}{' '}
                    <span className="hidden lg:block">
                      &nbsp;{numberOfPlayers > 1 ? 'players' : 'player'}
                    </span>
                  </Body>
                </div>
              )}
              {question && (
                <div className="bg-neutral-100 rounded-md flex items-center p-10 ml-10 hidden lg:block">
                  <Body small>
                    Round {question.round_number}: Question{' '}
                    {question.question_number}
                  </Body>
                </div>
              )}
              {isStreamingBlock ? (
                <button className="bg-neutral-100 rounded-md flex items-center ml-10 aspect-ratio-1:1 w-[40px] h-[40px] text-center justify-center relative">
                  <div className="inset-0 absolute w-full h-full z-10 rounded-lg bg-opacity-50 pointer-events-none bg-white">
                    <svg
                      className="absolute inset-0 m-auto animate-spin text-neutral-700"
                      width="20"
                      height="20"
                      viewBox="0 0 120 120"
                      fill="none"
                    >
                      <path
                        d="M100.305 19.6949C109.63 29.0197 115.433 41.2893 116.726 54.413C118.018 67.5368 114.72 80.7027 107.394 91.6675C100.067 102.632 89.1656 110.718 76.5462 114.546C63.9268 118.374 50.3705 117.708 38.187 112.661C26.0036 107.615 15.9469 98.4997 9.73049 86.8696C3.51406 75.2395 1.52253 61.8137 4.09524 48.8798C6.66795 35.946 13.6457 24.3043 23.8396 15.9384C34.0335 7.5725 46.8128 3 60 3"
                        stroke="currentColor"
                        strokeWidth="6"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </button>
              ) : (
                <>
                  {((!is_host && isStreaming) || is_live) && (
                    <button
                      onClick={() => setIsMuted(!isMuted)}
                      className="bg-neutral-100 rounded-md flex items-center ml-10 aspect-ratio-1:1 w-[40px] h-[40px] text-center justify-center"
                    >
                      {isMuted ? (
                        <svg
                          viewBox="0 0 461.55 461.55"
                          className="text-red-500 w-20 h-20 animate-pulse"
                        >
                          <g>
                            <g id="volume-off">
                              <path
                                className="fill-current"
                                d="M345.525,229.5c0-45.9-25.5-84.15-63.75-102v56.1l63.75,63.75C345.525,239.7,345.525,234.6,345.525,229.5z M409.275,229.5c0,22.95-5.1,45.9-12.75,66.3l38.25,38.25c17.85-30.6,25.5-68.85,25.5-107.1c0-109.65-76.5-201.45-178.5-224.4V56.1C355.725,81.6,409.275,147.9,409.275,229.5z M34.425,0L1.275,33.15L121.125,153H1.275v153h102l127.5,127.5V262.65L340.425,372.3c-17.851,12.75-35.7,22.95-58.65,30.601v53.55c35.7-7.65,66.3-22.95,94.35-45.9l51,51l33.15-33.149l-229.5-229.5L34.425,0zM230.775,25.5l-53.55,53.55l53.55,53.55V25.5z"
                              />
                            </g>
                          </g>
                        </svg>
                      ) : (
                        <svg viewBox="0 0 459 459" className="w-20 h-20">
                          <g>
                            <g>
                              <path d="M0,153v153h102l127.5,127.5v-408L102,153H0z M344.25,229.5c0-45.9-25.5-84.15-63.75-102v204C318.75,313.65,344.25,275.4,344.25,229.5z M280.5,5.1v53.55C354.45,81.6,408,147.899,408,229.5S354.45,377.4,280.5,400.35V453.9C382.5,430.949,459,339.15,459,229.5C459,119.85,382.5,28.049,280.5,5.1z" />
                            </g>
                          </g>
                        </svg>
                      )}
                      <span className="sr-only">
                        {isMuted ? 'Unmute' : 'Mute'} video
                      </span>
                    </button>
                  )}
                  {is_host && !is_live && (
                    <button
                      onClick={() => setIsStreaming(!isStreaming)}
                      className="bg-neutral-100 rounded-md flex items-center ml-10 aspect-ratio-1:1 w-[40px] h-[40px] text-center justify-center"
                    >
                      {isStreaming ? (
                        <svg
                          viewBox="0 0 484.5 484.5"
                          className="text-red-500 w-20 h-20"
                        >
                          <g>
                            <g>
                              <path
                                className="fill-current"
                                d="M484.5,114.75l-102,102V127.5c0-15.3-10.2-25.5-25.5-25.5H198.9l285.6,285.6V114.75z M33.15,0L0,33.15L68.85,102H51c-15.3,0-25.5,10.2-25.5,25.5v255c0,15.3,10.2,25.5,25.5,25.5h306c5.1,0,10.2-2.55,12.75-5.1l81.6,81.6l33.15-33.15L33.15,0z"
                              />
                            </g>
                          </g>
                        </svg>
                      ) : (
                        <svg viewBox="0 0 459 459" className="w-20 h-20">
                          <g>
                            <g id="videocam">
                              <path
                                className="fill-current"
                                d="M357,191.25V102c0-15.3-10.2-25.5-25.5-25.5h-306C10.2,76.5,0,86.7,0,102v255c0,15.3,10.2,25.5,25.5,25.5h306c15.3,0,25.5-10.2,25.5-25.5v-89.25l102,102V89.25L357,191.25z"
                              />
                            </g>
                          </g>
                        </svg>
                      )}
                      <span className="sr-only">
                        {isStreaming ? 'Unmute' : 'Mute'} video
                      </span>
                    </button>
                  )}
                </>
              )}
              {is_host && (
                <button
                  onClick={() => setIsMusicMuted(!isMusicMuted)}
                  className="bg-neutral-100 rounded-md flex items-center ml-10 aspect-ratio-1:1 w-[40px] h-[40px] text-center justify-center"
                >
                  {isMusicMuted ? (
                    <svg
                      viewBox="100 40 484.5 484.5"
                      className="text-red-500 w-25 h-25"
                    >
                      <path
                        className="fill-current"
                        d="m128.34 87.5c3.8672-5.1562 11.18-6.1992 16.336-2.3359l438.66 329c5.1562 3.8633 6.1992 11.176 2.332 16.332l-14 18.668c-3.8633 5.1523-11.176 6.1953-16.332 2.332l-112-84.004v87.758c0 6.2773-4.6875 10.805-10.086 11.57-0.45703 0.0625-0.91406 0.10547-1.3789 0.11328-2.4141 0.054688-4.9023-0.65234-7.125-2.293l-124.01-91.312h-79.074c-6.4453 0-11.668-5.2227-11.668-11.668v-163.33c0-1.7891 0.40234-3.4844 1.1211-4.9961l-94.445-70.836c-5.1562-3.8672-6.1992-11.18-2.3359-16.336zm301.98 5.6328c-1.9102 0.21875-3.8203 0.92969-5.5664 2.2188l-118.78 87.461 137.36 103.02v-0.003906l0.007813 0.003906v-181.09c0-7.1758-6.1211-12.066-12.43-11.668-0.19922 0.011719-0.39453 0.03125-0.59375 0.054687z"
                        fillRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg viewBox="100 40 484.5 484.5" className="w-25 h-25">
                      <path
                        className="fill-current"
                        d="m221.67 373.33c-6.4453 0-11.668-5.2227-11.668-11.668v-163.33c0-6.4453 5.2227-11.668 11.668-11.668h79.074l124.01-91.312c7.7031-5.6758 18.582-0.17187 18.582 9.3945v350.51c0 9.5664-10.879 15.066-18.582 9.3906l-124.01-91.312z"
                        fillRule="evenodd"
                      />
                    </svg>
                  )}
                  <span className="sr-only">
                    {isMusicMuted ? 'Unmute' : 'Mute'} music
                  </span>
                </button>
              )}
              {startleId && (
                <button
                  onClick={() => {
                    if (isCasting == 'loading') {
                      return null
                    } else {
                      castHandler()
                    }
                  }}
                  onMouseEnter={() => setCastHighlight(true)}
                  onMouseLeave={() => setCastHighlight(false)}
                  className="relative bg-neutral-100 rounded-md flex items-center ml-10 aspect-ratio-1:1 w-[45px] h-[40px] text-center justify-center"
                >
                  {isCasting == 'loading' ? (
                    <svg
                      className="animate-spin text-neutral-700"
                      width="20"
                      height="20"
                      viewBox="0 0 120 120"
                      fill="none"
                    >
                      <path
                        d="M100.305 19.6949C109.63 29.0197 115.433 41.2893 116.726 54.413C118.018 67.5368 114.72 80.7027 107.394 91.6675C100.067 102.632 89.1656 110.718 76.5462 114.546C63.9268 118.374 50.3705 117.708 38.187 112.661C26.0036 107.615 15.9469 98.4997 9.73049 86.8696C3.51406 75.2395 1.52253 61.8137 4.09524 48.8798C6.66795 35.946 13.6457 24.3043 23.8396 15.9384C34.0335 7.5725 46.8128 3 60 3"
                        stroke="currentColor"
                        strokeWidth="6"
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <Cast casting={isCasting} />
                  )}

                  <div
                    className={`${
                      castHighlight ? 'opacity-1' : 'opacity-0'
                    } transition-opacity font-bold text-white absolute animate-bounce-slow bg-neutral-800 top-full rounded-md pointer-events-none  z-40 p-10 max-w-xs w-screen mt-14 border-b-[2px] border-b-neutral-900`}
                  >
                    <div className="left-1/2 -translate-x-1/2 absolute -top-8 w-0 h-0 border-l-[10px] border-r-[10px] border-b-[10px] border-l-transparent border-r-transparent border-b-neutral-800"></div>

                    {isCasting == 'loading' && (
                      <>Connecting to your Startle device</>
                    )}
                    {isCasting === true && (
                      <>Stop casting to your Startle device</>
                    )}
                    {isCasting === false && (
                      <>Cast this quiz to your Startle device</>
                    )}
                  </div>
                </button>
              )}

              {pin && !is_live && (
                <div className="relative">
                  <CopyToClipboard text={QRurl} onCopy={() => setCopied(true)}>
                    <button className="bg-neutral-100 rounded-md flex items-center p-10 ml-10">
                      <Body className="hidden lg:block" small>
                        KwizzPIN:&nbsp;
                        {pin}
                      </Body>
                      <Body className="block lg:hidden" small>
                        PIN: {pin}
                      </Body>
                    </button>
                  </CopyToClipboard>
                  <Transition
                    show={copied}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    as="div"
                  >
                    <CheckCircle className="absolute top-5 -right-10 w-20 h-20 text-green-500" />
                  </Transition>
                </div>
              )}
            </div>
            <div className="flex justify-center">
              {ad_takeover_url && (
                <div
                  className={`relative w-80 mr-10  ${showAd ? `` : `hidden`}`}
                >
                  <ReactPlayer
                    url={ad_takeover_url}
                    playing={showAd}
                    onEnded={() => setShowAd(false)}
                    className="absolute top-0 left-0"
                    width="100%"
                    height="100%"
                    muted={true}
                    volume={0.5}
                  />
                </div>
              )}

              {is_host ? (
                <>
                  {ad_takeover_url && (
                    <Button
                      theme="small"
                      fullWidth={false}
                      onClick={() => {
                        playAd()
                      }}
                      className="mr-10"
                    >
                      <span className="font-normal hidden md:block">
                        {showAd ? 'Playing...' : 'Play Ad'}
                      </span>
                    </Button>
                  )}

                  {quizStatus === 'not_started' ? (
                    <Button
                      theme="small"
                      fullWidth={false}
                      onClick={() => {
                        setIsStreaming(false)
                        router.push('/dashboard')
                      }}
                    >
                      <span className="font-normal hidden md:block">
                        Back to dashboard
                      </span>
                      <span className="font-normal block md:hidden">Back</span>
                    </Button>
                  ) : (
                    <button
                      onClick={() => setShowDialog(true)}
                      className="flex items-center p-10 self-end"
                    >
                      <span className="font-normal mr-10 hidden md:block">
                        End game
                      </span>
                      <svg
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                      >
                        <path
                          d="M0.667969 2.00065C0.667969 1.08018 1.41416 0.333984 2.33464 0.333984H9.83464C10.7551 0.333984 11.5013 1.08018 11.5013 2.00065V3.66732C11.5013 4.12756 11.1282 4.50065 10.668 4.50065C10.2077 4.50065 9.83464 4.12755 9.83464 3.66732V2.00065H2.33464V12.0007H9.83464V10.334C9.83464 9.87373 10.2077 9.50065 10.668 9.50065C11.1282 9.50065 11.5013 9.87373 11.5013 10.334V12.0007C11.5013 12.9212 10.7551 13.6673 9.83464 13.6673H2.33464C1.41416 13.6673 0.667969 12.9212 0.667969 12.0007V2.00065ZM13.4121 3.91139C13.7375 3.58596 14.2651 3.58596 14.5906 3.91139L17.0906 6.4114C17.416 6.73682 17.416 7.26448 17.0906 7.5899L14.5906 10.0899C14.2651 10.4153 13.7375 10.4153 13.4121 10.0899C13.0866 9.76448 13.0866 9.23682 13.4121 8.9114L14.4895 7.83398H6.5013C6.04107 7.83398 5.66797 7.4609 5.66797 7.00065C5.66797 6.5404 6.04107 6.16732 6.5013 6.16732H14.4895L13.4121 5.08991C13.0866 4.76447 13.0866 4.23683 13.4121 3.91139Z"
                          fill="#3D435E"
                        />
                      </svg>
                    </button>
                  )}
                </>
              ) : (
                <button
                  onClick={() => setShowDialog(true)}
                  className="flex items-center p-10 self-end"
                >
                  <span className="font-normal mr-10">
                    {is_host ? 'End' : 'Leave'} game
                  </span>
                  <svg width="18" height="14" viewBox="0 0 18 14" fill="none">
                    <path
                      d="M0.667969 2.00065C0.667969 1.08018 1.41416 0.333984 2.33464 0.333984H9.83464C10.7551 0.333984 11.5013 1.08018 11.5013 2.00065V3.66732C11.5013 4.12756 11.1282 4.50065 10.668 4.50065C10.2077 4.50065 9.83464 4.12755 9.83464 3.66732V2.00065H2.33464V12.0007H9.83464V10.334C9.83464 9.87373 10.2077 9.50065 10.668 9.50065C11.1282 9.50065 11.5013 9.87373 11.5013 10.334V12.0007C11.5013 12.9212 10.7551 13.6673 9.83464 13.6673H2.33464C1.41416 13.6673 0.667969 12.9212 0.667969 12.0007V2.00065ZM13.4121 3.91139C13.7375 3.58596 14.2651 3.58596 14.5906 3.91139L17.0906 6.4114C17.416 6.73682 17.416 7.26448 17.0906 7.5899L14.5906 10.0899C14.2651 10.4153 13.7375 10.4153 13.4121 10.0899C13.0866 9.76448 13.0866 9.23682 13.4121 8.9114L14.4895 7.83398H6.5013C6.04107 7.83398 5.66797 7.4609 5.66797 7.00065C5.66797 6.5404 6.04107 6.16732 6.5013 6.16732H14.4895L13.4121 5.08991C13.0866 4.76447 13.0866 4.23683 13.4121 3.91139Z"
                      fill="#3D435E"
                    />
                  </svg>
                </button>
              )}
            </div>
          </nav>
        </div>
      </div>
      <DialogPopupWithBack
        openModal={() => setEndCastingOverlay(true)}
        closeModal={() => setEndCastingOverlay(false)}
        isOpen={endCastingOverlay}
      >
        <Bravo className="pb-20">Do you want to end your Startle cast</Bravo>
        <div>
          <Button
            onClick={() => castHandler(true)}
            label="End cast"
            fullWidth={false}
            className="mx-auto"
          />
        </div>
      </DialogPopupWithBack>
    </>
  )
}
